import React, { useEffect, useRef, useState } from "react";

import { Form, Link, useLocation, useSearchParams } from "@remix-run/react";
import { useEnv, useWasteTypes } from "app/root.tsx";
import { ObfuscatableLink } from "components/Interface/ObfuscatableLink";
import { ActionButton } from "components/Layout/NavbarButtons.tsx";
import FullLogo from "components/Media/FullLogo.tsx";
import LogoIcon from "components/Media/LogoIcon.tsx";
import { Headline, headlineVariants } from "components/UI/Headline";
import { Label, LabelVariants } from "components/UI/Label";
import { Paragraph } from "components/UI/Paragraph";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/shadcn-ui/accordion.tsx";
import { ClintButton } from "components/shadcn-ui/button";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "components/shadcn-ui/navigation-menu.tsx";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "components/shadcn-ui/sheet";
import { useLogoutUrl } from "hooks/useLogoutUrl.tsx";
import { useMarketplaceSearchBar } from "hooks/useMarketplaceSearchBar";
import { useOptionalUser } from "hooks/useUser.tsx";
import { Crown, Edit, LogOut, Menu, Phone, User, Xmark } from "iconoir-react";
import { usePublicLayout } from "routes/_public+/_layout";
import { FiltersModal } from "routes/_public+/solutions+/rechercher";
import { cn } from "utils/utils.ts";
const links: {
  name: string;
  link: string;
  obfuscate: boolean;
  hideOnLowWidth?: boolean;
  dropdown?: {
    name: string;
    sublinks: {
      name: string;
      link: string;
      image: string;
      alt: string;
      obfuscate: boolean;
    }[];
    subCtas: {
      name: string;
      link: string;
      primary: boolean;
      obfuscate: boolean;
    }[];
  };
}[] = [
  {
    name: "Solutions",
    link: "/",
    obfuscate: true,
    dropdown: {
      name: "Nos solutions",
      sublinks: [
        {
          name: "Bennes",
          link: "/solutions/location-de-bennes",
          image: "/images/bennes.png",
          alt: "Location bennes déchets",
          obfuscate: false,
        },

        {
          name: "Big bags",
          link: "/solutions/enlevement-big-bag",
          image: "/images/bigbags.png",
          alt: "Collecte big bags gravats",
          obfuscate: false,
        },
        // {
        //   name: "Sacs",
        //   link: "/solutions#enlevement-big-bag",
        //   image: "/images/sac.png",
        //   alt: "Collecte sacs gravats",
        //   obfuscate: true,
        // },
        // {
        //   name: "Vrac",
        //   link: "/solutions#vrac",
        //   image: "/images/vrac.png",
        //   alt: "Collecte déchets en vrac",
        //   obfuscate: true,
        // },
      ],
      subCtas: [
        {
          name: "Toutes nos Solutions",
          link: "/solutions",
          primary: false,
          obfuscate: false,
        },
      ],
    },
  },
  {
    name: "Conseil",
    link: "/conseil",
    obfuscate: false,
  },

  {
    name: `Blog`,
    link: "/blog",
    hideOnLowWidth: true,
    obfuscate: false,
  },
  {
    name: "Contact",
    link: "/contact",
    obfuscate: false,
  },
];

export const Navigation = () => {
  const [openDropdown, setOpenDropDown] = React.useState(false);
  const { phoneLink, phoneDisplay } = useEnv() || {};
  // Check if current url contains segment /solutions/rechercher. Make it an array of urls
  const searchUrls = ["/solutions/rechercher"];
  const { pathname } = useLocation();

  const isSearchPage = searchUrls.some((url) => pathname.includes(url));
  return (
    <NavigationMenu
      id="navbar"
      className={cn(
        "sticky top-0 z-50 flex h-full w-full flex-col items-center justify-center bg-gray-100 px-2 py-3 transition-colors duration-100 md:px-4 lg:px-10 lg:py-5",
        {
          "max-h-[145px] lg:max-h-[214px]": isSearchPage,
          "max-h-[68px] lg:max-h-[84px]": !isSearchPage,
          "border-b border-gray-200": isSearchPage,
        },
      )}
    >
      <div className="m-auto flex w-full max-w-[1280px] flex-col gap-5">
        <div className="flex w-full items-center justify-between md:justify-center md:gap-8">
          <GoodCollectLogoLink />
          <div className="hidden md:flex md:flex-1 md:items-center md:justify-end md:gap-6">
            {links.map((link) => (
              <NavlinkItem key={link.name} link={link} />
            ))}
          </div>

          <NavigationMenuList className="ml-auto flex w-full items-center lg:py-1 xl:py-2">
            <ClintButton
              as="Link"
              variant={"tertiary"}
              size="xs"
              linkProps={{
                to: phoneLink || "",
              }}
              hideIcon
              className="mr-4 xl:hidden"
            >
              {phoneDisplay}
            </ClintButton>
            <UserActions className="ml-auto hidden xl:flex" />

            <div className="relative w-full xl:hidden">
              <Sheet
                open={openDropdown}
                key={useLocation().key}
                onOpenChange={(el) => {
                  setOpenDropDown(el);
                }}
              >
                <SheetTrigger aria-label="Ouvrir le menu">
                  <span className="flex items-center align-baseline md:hidden">
                    <span className="text-teal focus:ring-teal inline-flex items-center justify-center rounded-md p-2 px-0 hover:bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset">
                      <span className="sr-only">Ouvrir le menu</span>
                      {openDropdown ? (
                        <Xmark className="size-6 shrink-0" aria-hidden="true" />
                      ) : (
                        <Menu
                          className="text-midnightblue size-6 shrink-0"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </span>

                  <span className="hidden flex-shrink-0 md:block">
                    <Menu
                      className="text-midnightblue size-6 shrink-0"
                      aria-hidden="true"
                    />
                  </span>
                </SheetTrigger>
                <SheetContent
                  hideCloseIcon
                  className={`w-screen max-w-full bg-gray-100 p-0 data-[state=closed]:duration-150 data-[state=open]:duration-200`}
                >
                  <div className="relative flex h-full flex-col">
                    <SheetHeader className="flex flex-row items-center justify-between border-b border-gray-200 px-5 py-4">
                      <SheetTitle asChild>
                        <GoodCollectLogoLink />
                      </SheetTitle>
                      <SheetClose>
                        <Xmark className="size-6" />
                        <span className="sr-only">Fermer</span>
                      </SheetClose>
                    </SheetHeader>
                    <div className={`flex flex-col gap-0 px-6`}>
                      {links.map((link) => (
                        <NavlinkItem key={link.link} mobile link={link} />
                      ))}
                    </div>

                    {/* <div
                    className={`text-midnightblue hidden flex-col gap-2 pb-3 pt-2 md:flex xl:hidden`}
                  >
                    {links
                      .filter((l) => l.hideOnLowWidth)
                      .map((slink) => (
                        <ObfuscatableNavLink
                          obfuscate={slink.obfuscate}
                          key={slink.name}
                          to={slink.link}
                          prefetch="intent"
                          className={({ isActive }: { isActive: boolean }) =>
                            `text-midnightblue flex flex-row gap-4 rounded-md border-transparent bg-transparent py-2 pl-2 pr-2 text-base ${isActive ? "font-bold" : "font-normal"
                            }`
                          }
                        >
                          {slink.name}
                        </ObfuscatableNavLink>
                      ))}
                  </div> */}

                    {/* <Separator color={"border-t-iron"} /> */}
                    <UserActions />
                    {/* <SheetFooter className="sticky bottom-0 mt-auto w-full border-t border-t-gray-200 bg-gray-100 px-10 py-6">
                  </SheetFooter> */}
                  </div>
                </SheetContent>
              </Sheet>
            </div>
          </NavigationMenuList>
        </div>
        {isSearchPage ? <NavigationSteps className="w-full" /> : null}
      </div>
    </NavigationMenu>
  );
};

const NavlinkItem = ({
  link,
  mobile = false,
  hideOnLowWidth = false,
}: {
  link: (typeof links)[0];
  mobile?: boolean;
  hideOnLowWidth?: boolean;
}) => {
  const isDropdown = link.dropdown && link.dropdown.sublinks.length > 0;
  const location = useLocation();

  if (mobile && isDropdown) {
    return (
      <Accordion type="single" collapsible className="border-none">
        <AccordionItem className="border-b-0" value={link.name}>
          <AccordionTrigger
            theme="nav"
            className="hover:border-b-teal hover:text-teal w-full border-b border-b-gray-200 p-0 py-6"
          >
            <Headline as="span" size="h5">
              {link.name}
            </Headline>
          </AccordionTrigger>
          <AccordionContent asChild>
            <div className={`flex w-full flex-col items-start gap-6 py-6`}>
              {link.dropdown?.sublinks.map((slink) => (
                <Link
                  key={slink.name}
                  to={slink.link}
                  reloadDocument
                  className={cn(
                    headlineVariants({ size: "h6" }),
                    "hover:text-teal w-full",
                  )}
                >
                  {slink.name}
                </Link>
              ))}
              {link.dropdown?.subCtas.map((slink) => (
                <Link
                  key={slink.name}
                  to={slink.link}
                  reloadDocument
                  className={cn(
                    headlineVariants({ size: "h6" }),
                    "hover:text-teal w-full",
                  )}
                >
                  {slink.name}
                </Link>
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    );
  }

  if (isDropdown) {
    return (
      <NavigationMenuItem className={"z-30 cursor-pointer list-none p-0"}>
        <NavigationMenuTrigger className="px-2.5 py-2">
          <Label variant={"primary"} size="S">
            {link.name}
          </Label>
        </NavigationMenuTrigger>
        <NavigationMenuContent className="flex flex-col items-start gap-6 rounded-[16px] bg-white p-6">
          <div className="flex w-full flex-col items-start gap-4">
            {link.dropdown?.sublinks.map((slink) => (
              <NavigationMenuLink
                key={slink.name}
                href={slink.link}
                className={LabelVariants({
                  size: "M",
                  className: `w-full cursor-pointer ${
                    location.pathname + location.hash === slink.link
                      ? "text-bolder hover:text-primary"
                      : "hover:text-primary"
                  }`,
                })}
              >
                {slink.name}
              </NavigationMenuLink>
            ))}
          </div>

          {link.dropdown?.subCtas.map((cta) => (
            <NavigationMenuLink
              key={cta.name}
              className={LabelVariants({
                size: "M",
                className: `hover:border-b-teal cursor-pointer border-b border-b-inherit ${
                  location.pathname + location.hash === cta.link
                    ? "text-bolder hover:text-primary"
                    : "hover:text-primary"
                }`,
              })}
              href={cta.link}
            >
              {cta.name}
            </NavigationMenuLink>
          ))}
        </NavigationMenuContent>
      </NavigationMenuItem>
    );
  }

  // if (mobile) {
  //   return (
  //     <NavigationMenuItem asChild>
  //       <Link
  //         key={link.name}
  //         to={link.link}
  //         className={cn(
  //           headlineVariants({ size: "h5" }),
  //           "inline-flex hover:text-teal py-6 border-b border-gray-200 hover:border-b-teal",

  //           {
  //             "hidden xl:inline-flex": link?.hideOnLowWidth && hideOnLowWidth,
  //           },
  //         )}
  //       >
  //         {link.name}
  //       </Link>
  //     </NavigationMenuItem>
  //   );
  // }
  return (
    <NavigationMenuLink
      className={cn(
        mobile
          ? headlineVariants({ size: "h5" })
          : LabelVariants({ size: "S" }),
        "hover:text-teal inline-flex px-2.5 py-2",

        {
          "hover:border-b-teal border-b border-gray-200 py-6": mobile,
          "px-2.5 py-2": !mobile,
          "hidden xl:inline-flex": link?.hideOnLowWidth && hideOnLowWidth,
          "font-bold": !mobile && location.pathname === link.link,
        },
      )}
      href={link.link}
    >
      {/* <Link
        key={link.name}
        to={link.link}
        className={cn(
          LabelVariants({ size: "S" }),
          "inline-flex hover:text-teal px-2.5 py-2",

          {
            "hidden xl:inline-flex": link?.hideOnLowWidth && hideOnLowWidth,
            "font-bold": location.pathname === link.link,
          },
        )}
      > */}
      {link.name}
      {/* </Link> */}
    </NavigationMenuLink>
  );
};

const UserActions = ({ className = "" }: { className?: string }) => {
  const user = useOptionalUser();
  const { redirectTo } = useLogoutUrl();
  const { phoneLink, phoneDisplay } = useEnv() || {};

  return (
    <div
      className={cn(
        `flex w-full flex-shrink flex-col flex-wrap justify-end gap-4 px-6 pt-10 xl:ml-auto xl:w-fit xl:flex-row xl:items-center xl:gap-2 xl:px-0 xl:pt-0`,
        className,
      )}
    >
      {!user ? (
        <ClintButton
          as="Link"
          data-testid="login-link"
          variant={"tertiary"}
          size="sm"
          linkProps={{
            to: "/login",
          }}
          className={cn(
            "hidden items-center justify-center rounded border border-gray-300 px-3 pb-3.5 pt-3 lg:flex",
          )}
        >
          Se connecter
        </ClintButton>
      ) : (
        <>
          <div className="flex flex-row flex-wrap items-center text-sm">
            {user?.authOptions.isAdmin ? (
              <Form
                method="POST"
                className="inline-flex h-fit"
                action="/provider/reconnect-as-admin"
              >
                <button type="submit" className="px-1 text-amber-600 underline">
                  <Crown className="h-6 w-6 flex-shrink-0" aria-hidden="true" />
                </button>
              </Form>
            ) : (
              <ObfuscatableLink obfuscate to="/auth" className="text-teal">
                <User className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              </ObfuscatableLink>
            )}

            <ObfuscatableLink obfuscate to="/auth" className="text-teal">
              <span className={`underline decoration-current`}>
                {user.firstname}
              </span>
            </ObfuscatableLink>
          </div>

          <Form
            method="POST"
            action="/logout"
            reloadDocument
            className="inline-flex items-center"
          >
            <ActionButton
              spacing="none"
              type="submit"
              color="secondary"
              name="redirectTo"
              value={redirectTo}
            >
              <LogOut className="flex-shrink-0" aria-hidden="true" />
              <span className="font-normal"> Se déconnecter</span>
            </ActionButton>
          </Form>
        </>
      )}
      <ClintButton
        as="Link"
        variant={"secondary"}
        size="sm"
        linkProps={{
          to: phoneLink || "",
        }}
        Icon={Phone}
      >
        {phoneDisplay}
      </ClintButton>
      <ClintButton
        as="Link"
        variant={"primary"}
        size="sm"
        linkProps={{
          to: "/contact",
        }}
      >
        Demander un devis
      </ClintButton>
      <ClintButton
        as="Link"
        data-testid="login-link"
        variant={"tertiary"}
        size="sm"
        linkProps={{
          to: "/login",
        }}
        className={cn(
          "flex items-center justify-center rounded border border-gray-300 px-3 pb-3.5 pt-3 lg:hidden",
        )}
      >
        Se connecter
      </ClintButton>
    </div>
  );
};

export const NavigationSteps = ({ className }: { className?: string }) => {
  const wasteTypes = useWasteTypes();
  const publicLayout = usePublicLayout();

  const { selectedFilters, setSelectedFilters } = useMarketplaceSearchBar({
    defaultService: 1,
  });

  const wasteType = wasteTypes?.find(
    (wt) => wt.gcId === Number(selectedFilters.waste),
  );
  const isStepTwoDisabled =
    !selectedFilters.waste ||
    !selectedFilters.address ||
    !selectedFilters.placeId;
  const [searchParams] = useSearchParams();

  const providerId = searchParams.get("providerId") || null;
  const equipmentPriceRuleId = searchParams.get("equipmentPriceRuleId") || null;

  const isStepThreeDisabled =
    isStepTwoDisabled || !providerId || !equipmentPriceRuleId;

  const [isOpen, setIsOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  // This code scrolls to active tab in mobile view
  useEffect(() => {
    const activeTab = containerRef.current?.querySelector(
      `#step-${selectedFilters.currentStep}`,
    );
    if (activeTab && containerRef.current) {
      const activeTabLeft = activeTab.getBoundingClientRect().left;
      const containerLeft = containerRef.current.getBoundingClientRect().left;
      containerRef.current.scrollLeft = activeTabLeft - containerLeft;
    }
  }, []);
  const offerCount = publicLayout?.count || 0;

  return (
    <div
      className={cn(
        "flex shrink-0 items-stretch gap-4 overflow-x-auto lg:h-[110px]",
        className,
      )}
      ref={containerRef}
    >
      <FiltersModal
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <NavigationStep
        active={selectedFilters.currentStep === 1}
        infoName={wasteType ? wasteType.name : ""}
        infoDetails={selectedFilters.address}
        step={1}
        stepLabel={"Recherche"}
        completed={
          selectedFilters.currentStep > 1 && Boolean(selectedFilters.waste)
        }
        onEditClick={() => {
          setIsOpen(true);
        }}
      />
      <NavigationStep
        active={selectedFilters.currentStep === 2}
        disabled={isStepTwoDisabled}
        infoDetails={
          selectedFilters.currentStep >= 2 && offerCount > 0
            ? "Offres recommandées"
            : ""
        }
        stepLabel="Offres"
        infoName={
          selectedFilters.currentStep >= 2 && offerCount > 0
            ? offerCount.toString()
            : ""
        }
        step={2}
        completed={selectedFilters.currentStep >= 2}
        onEditClick={() => {
          setIsOpen(true);
        }}
      />
      <NavigationStep
        active={selectedFilters.currentStep === 3}
        disabled={isStepThreeDisabled}
        infoDetails={
          selectedFilters.currentStep >= 3 && publicLayout?.offer
            ? `${publicLayout?.offer?.offer.equipment.type.name} ${publicLayout.offer.offer.equipment.volume}m3`
            : ""
        }
        infoName={selectedFilters.currentStep >= 3 ? "Location benne" : ""}
        step={3}
        stepLabel="Solution"
        completed={selectedFilters.currentStep > 3 && !isStepThreeDisabled}
        onEditClick={() => {
          setIsOpen(true);
        }}
      />
      <NavigationStep
        active={selectedFilters.currentStep === 4}
        disabled={isStepThreeDisabled}
        infoDetails={
          selectedFilters.currentStep === 4 && publicLayout?.offer
            ? `${publicLayout?.offer?.offer.equipment.type.name} ${publicLayout.offer.offer.equipment.volume}m3`
            : ""
        }
        infoName={selectedFilters.currentStep === 4 ? "Location benne" : ""}
        step={4}
        stepLabel="Réservation"
        completed={selectedFilters.currentStep > 4}
        onEditClick={() => {
          setIsOpen(true);
        }}
      />
    </div>
  );
};

export const NavigationStep = ({
  step,
  stepLabel,
  completed = false,
  disabled = false,
  active = false,
  infoName,
  infoDetails,
  onEditClick,
}: {
  step: number;
  stepLabel: string;
  active?: boolean;
  completed?: boolean;
  disabled?: boolean;
  infoName: string;
  infoDetails: string;
  onEditClick?: () => void;
}) => {
  return (
    <div
      id={`step-${step}`}
      className={cn(
        `border-grey-300 h-full w-full min-w-[min(fit,110px)] grow self-stretch rounded-[8px] border-2 p-4`,
        {
          "bg-gray-50": disabled,
          "border-green-500 bg-white": active,
          "border-green-100 bg-green-100 text-gray-600": completed,
        },
      )}
    >
      <div className="flex flex-col gap-3.5">
        <div className="flex items-center justify-between gap-2">
          <Paragraph
            size="md"
            fontWeight={"bold"}
            variant="primary"
            className={cn("whitespace-nowrap", {
              "text-gray-300": disabled,
              "text-gray-600": completed,
              "text-green-600": active,
            })}
          >
            {`${step.toString().padStart(2, "0")}. ${stepLabel}`}
          </Paragraph>
          {completed ? (
            <button
              type="button"
              onClick={() => {
                onEditClick?.();
                // setSearchParams(
                //   (oldSearchParams) => {
                //     oldSearchParams.set("step", step.toString());
                //     return oldSearchParams;
                //   },
                //   {
                //     preventScrollReset: true,
                //   },
                // );
              }}
              className="size-5 shrink-0"
            >
              <Edit aria-hidden="true" />
            </button>
          ) : null}
        </div>
        {disabled ? null : (
          <div className="hidden flex-col items-start gap-1.5 lg:flex">
            <Paragraph size="sm" fontWeight="bold" className="text-gray-700">
              {infoName}
            </Paragraph>
            <Paragraph
              size="sm"
              className="max-w-[26ch] overflow-hidden truncate text-ellipsis text-gray-700 [-webkit-box-orient:vertical] [-webkit-line-clamp:1] [display:-webkit-box]"
            >
              {infoDetails}
            </Paragraph>
          </div>
        )}
      </div>
    </div>
  );
};

const GoodCollectLogoLink = () => {
  return (
    <Link
      to="/"
      aria-label="Aller à la page d'accueil"
      className="relative flex h-[41px] w-fit flex-shrink-0 flex-row items-center gap-5 overflow-hidden outline-none"
    >
      <div
        className={`fill-teal text-teal flex h-6 w-auto max-w-[26px] flex-shrink-0 items-center lg:max-w-[41px]`}
      >
        <LogoIcon />
      </div>
      <div
        className={`text-midnightblue flex h-6 w-auto max-w-[100px] flex-shrink-0 items-center lg:max-w-[160px]`}
      >
        <FullLogo />
      </div>
    </Link>
  );
};
