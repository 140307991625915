import { MarketplaceOffersType } from "@goodcollect/shared/bookings";
import { useMarketplaceSideFilters } from "hooks/useMarketplaceSideFilters.tsx";
import { MarketplaceSideFilterWrapper } from "../MarketplaceSideFilters.tsx";
import { SideFilterCheckbox } from "./SideFilterCheckbox.tsx";

export const VolumeFilter = ({
  toggleVolume,
  availableVolumes,
  selectedVolumes,
  volumes,
}: {
  toggleVolume: ReturnType<typeof useMarketplaceSideFilters>["toggleVolume"];
  availableVolumes: MarketplaceOffersType["availableVolumes"];
  volumes: MarketplaceOffersType["volumes"];
  selectedVolumes: ReturnType<
    typeof useMarketplaceSideFilters
  >["sideFilters"]["selectedVolumes"];
}) => {
  return (
    <MarketplaceSideFilterWrapper title="Volume benne (m3)">
      <div className="flex flex-col gap-4">
        {volumes.map((option) => {
          const isAvailable = availableVolumes.includes(option);
          return (
            <SideFilterCheckbox
              checked={isAvailable && selectedVolumes.includes(option)}
              toggleOption={(valueId) => toggleVolume(Number(valueId))}
              optionName={`${option}m3`}
              optionGcId={option}
              key={`volume-${option}`}
              isDisabled={!isAvailable}
            />
          );
        })}
      </div>
    </MarketplaceSideFilterWrapper>
  );
};
