import { MarketplaceSearchFiltersSchema } from "@goodcollect/shared";
import { MarketplaceOffersType } from "@goodcollect/shared/bookings";
import { AppLoadContext } from "@remix-run/node";
import { useSearchParams } from "@remix-run/react";
import { useCallback, useMemo, useState } from "react";
import { z } from "zod";
export type MarketplaceSidebarFilters = {
  // price: number;
  // volume: number;
  showHiddenOffers: boolean;
  // selectedOptions: OfferTypeOption[];
  selectedEquipmentTypes: MarketplaceOffersType["availableEquipmentTypes"];
  selectedVolumes: MarketplaceOffersType["availableVolumes"];

  selectedTreatmentTypes: Awaited<
    ReturnType<
      AppLoadContext["remixService"]["providerBookingQueriesService"]["getTreatmentTypes"]
    >
  >["treatmentTypes"];
  selectedBillingModes: z.infer<
    typeof MarketplaceSearchFiltersSchema
  >["billingMode"][];
};

// export const defaultBillingModes: OfferTypeOption[] = [
//   { id: "", name: "Prix forfaitaire" },
//   { id: "real", name: "Prix à la tonne" },
// ];
export const useMarketplaceSideFilters = ({
  otherOffers,
  treatmentTypeData,
  availableEquipmentTypes,
  availableVolumes,
  hiddenOffers,
  bestPlanOffer,
  bestTonOffer,
}: Pick<
  MarketplaceOffersType,
  | "otherOffers"
  | "hiddenOffers"
  | "treatmentTypeData"
  | "availableEquipmentTypes"
  | "equipmentTypes"
  | "availableVolumes"
  | "volumes"
  | "bestPlanOffer"
  | "bestTonOffer"
>) => {
  const [searchParams] = useSearchParams();

  const [sideFilters, setSideFilters] = useState<MarketplaceSidebarFilters>({
    // price: mostExpensivePrice,
    // volume: mostVoluminousOffer,
    selectedTreatmentTypes: treatmentTypeData.selectedTreatmentTypes,
    // selectedOptions: defaultOfferTypes,
    selectedEquipmentTypes: availableEquipmentTypes,
    selectedVolumes: availableVolumes,
    showHiddenOffers: false,
    selectedBillingModes: ["per-ton", "recurring", "immediatePickup"],
  });

  // const setPrice = (price: number) => {
  //   setSideFilters((filters) => ({
  //     ...filters,
  //     price,
  //   }));
  // };

  const filterOffersByMaxPrice = useCallback(
    (offers: MarketplaceOffersType["otherOffers"]) => {
      if (sideFilters.selectedEquipmentTypes.length === 0) return [];
      if (sideFilters.selectedTreatmentTypes.length === 0) return [];
      if (sideFilters.selectedVolumes.length === 0) return [];
      return offers.filter((offer) => {
        // const isOfferUnderDisplayPrice = offer.priceHT <= sideFilters.price;
        const isTreatmentTypeSelected = sideFilters.selectedTreatmentTypes.some(
          (type) => type.gcId === offer.treatmentTypeId,
        );
        // const isPlanAndPlanIsSelected =
        //   offer.isPlan &&
        //   sideFilters.selectedOptions.some((type) => type.id === "plan");
        // const isRealAndRealIsSelected =
        //   !offer.isPlan &&
        //   sideFilters.selectedOptions.some((type) => type.id === "real");
        // const isOfferTypeSelected =
        //   isPlanAndPlanIsSelected || isRealAndRealIsSelected;

        const isOfferFromSelectedEquipmentType =
          sideFilters.selectedEquipmentTypes.some(
            (set) => set.id === offer.equipment.type.id,
          );

        const isOfferVolumeSelected = sideFilters.selectedVolumes.includes(
          offer.equipment.volume,
        );
        return (
          // isOfferUnderDisplayPrice &&
          isTreatmentTypeSelected &&
          // isOfferTypeSelected &&
          isOfferFromSelectedEquipmentType &&
          isOfferVolumeSelected
        );
      });
    },
    [
      // sideFilters.price,
      // sideFilters.selectedOptions,
      sideFilters.selectedTreatmentTypes,
      sideFilters.selectedEquipmentTypes,
      sideFilters.selectedVolumes,
    ],
  );

  // useEffect(() => {
  //   setSideFilters((filters) => ({
  //     ...filters,
  //     price: mostExpensivePrice,
  //   }));
  // }, [searchParams, mostExpensivePrice]);

  // const toggleOfferType = useCallback((optionId: string) => {
  //   setSideFilters((oldFilterData) => {
  //     // const { selectedOptions } = oldFilterData;
  //     // On a trouvé la valeur parmi les checkbox cochées, ça signifie que l'utilisateur souhaite décocher la checkbox
  //     const isSelected = selectedOptions.some((type) => type.id === optionId);

  //     return {
  //       ...oldFilterData,
  //       selectedOptions: isSelected
  //         ? // Déselection
  //         selectedOptions.filter((type) => type.id !== optionId)
  //         : [...selectedOptions, { id: optionId, name: optionId }],
  //     };
  //   });
  // }, []);

  const toggleEquipmentType = (equipmentTypeId: number) => {
    setSideFilters((oldFilterData) => {
      const { selectedEquipmentTypes } = oldFilterData;
      // On a trouvé la valeur parmi les checkbox cochées, ça signifie que l'utilisateur souhaite décocher la checkbox
      const isSelected = selectedEquipmentTypes.some(
        (type) => type.id === equipmentTypeId,
      );
      const selectedEquipmentType = availableEquipmentTypes.find(
        (et) => et.id === equipmentTypeId,
      );

      if (!selectedEquipmentType) {
        throw new Error("Equipment type id is required");
      }

      return {
        ...oldFilterData,
        selectedEquipmentTypes: isSelected
          ? // Déselection
            selectedEquipmentTypes.filter((type) => type.id !== equipmentTypeId)
          : [...selectedEquipmentTypes, selectedEquipmentType],
      };
    });
  };

  const toggleVolume = (volume: number) => {
    setSideFilters((oldFilterData) => {
      const { selectedVolumes } = oldFilterData;
      // On a trouvé la valeur parmi les checkbox cochées, ça signifie que l'utilisateur souhaite décocher la checkbox
      const isSelected = selectedVolumes.includes(volume);

      const selectedVolumesSet = new Set<number>(selectedVolumes);
      if (isSelected) {
        selectedVolumesSet.delete(volume);
      } else {
        selectedVolumesSet.add(volume);
      }

      return {
        ...oldFilterData,
        selectedVolumes: Array.from(selectedVolumesSet),
      };
    });
  };

  const toggleTreatmentType = useCallback(
    (treatmentTypeId: number | string) => {
      treatmentTypeId = Number(treatmentTypeId);
      const toggledTreatmentType = treatmentTypeData.treatmentTypes.find(
        (t) => t.gcId === treatmentTypeId,
      );
      if (!toggledTreatmentType) {
        throw new Error("Treatment type id is required");
      }

      setSideFilters((oldFilterData) => {
        const { selectedTreatmentTypes } = oldFilterData;
        // On a trouvé la valeur parmi les checkbox cochées, ça signifie que l'utilisateur souhaite décocher la checkbox
        const isSelected = selectedTreatmentTypes.some(
          (type) => type.gcId === treatmentTypeId,
        );

        return {
          ...oldFilterData,
          selectedTreatmentTypes: isSelected
            ? // Déselection
              selectedTreatmentTypes.filter(
                (type) => type.gcId !== treatmentTypeId,
              )
            : [...selectedTreatmentTypes, toggledTreatmentType],
        };
      });
    },
    [treatmentTypeData.treatmentTypes],
  );

  const filteredOtherOffers = useMemo(() => {
    if (sideFilters.showHiddenOffers) {
      return filterOffersByMaxPrice(otherOffers.concat(hiddenOffers));
    }
    const filteredOtherOffers = filterOffersByMaxPrice(otherOffers);
    if (filteredOtherOffers.length === otherOffers.length) {
      return filteredOtherOffers;
    }
    const slicedValue = otherOffers.length - filteredOtherOffers.length;
    const filteredHiddenOffers = filterOffersByMaxPrice(hiddenOffers).slice(
      0,
      slicedValue,
    );

    return filteredOtherOffers.concat(filteredHiddenOffers);
  }, [filterOffersByMaxPrice, otherOffers, sideFilters.showHiddenOffers]);

  const filteredHiddenOffersCount = useMemo(() => {
    const filteredOtherOffers = filterOffersByMaxPrice(otherOffers);
    const slicedValue = otherOffers.length - filteredOtherOffers.length;
    return filterOffersByMaxPrice(hiddenOffers).length - slicedValue;
  }, [filterOffersByMaxPrice, hiddenOffers, otherOffers]);

  const filteredBestPlanOffer = useMemo(() => {
    if (bestPlanOffer) {
      const filteredOffers = filterOffersByMaxPrice([bestPlanOffer]);
      if (filteredOffers.length > 0) {
        return filteredOffers[0];
      }
    }
    return null;
  }, [filterOffersByMaxPrice]);

  const filteredBestTonOffer = useMemo(() => {
    if (bestTonOffer) {
      const filteredOffers = filterOffersByMaxPrice([bestTonOffer]);
      if (filteredOffers.length > 0) {
        return filteredOffers[0];
      }
    }
    return null;
  }, [filterOffersByMaxPrice]);

  return {
    filteredOtherOffers,
    filteredBestPlanOffer,
    filteredBestTonOffer,
    toggleTreatmentType,
    // setPrice,
    setSideFilters,
    sideFilters,
    searchParams,
    toggleEquipmentType,
    toggleVolume,
    filteredHiddenOffersCount,
  };
};
