import { ClintButton } from "components/shadcn-ui/button";
import { Check } from "iconoir-react";
import { formatPriceWithCurrency } from "utils/format-price";
import { cn } from "utils/utils";
import { Headline } from "./Headline";
import { Label } from "./Label";
import { Tag } from "./Tag";

type CommonCardProps = {
  width: number;
  height: number;
  length: number;
  imageUrl: string;
  isProfessional: boolean;
  onQuoteClick: () => void;
  equipmentName: string;
  isPlan?: boolean;
};
type OfferType = {
  onBookClick: () => void;
  priceHT: number;
  priceTTC: number;
  type: "offer";
  equipmentWeight: number;
} & CommonCardProps;

type NoOfferType = {
  type: "no-offer";
} & CommonCardProps;

type CardOfferType = OfferType | NoOfferType;
export const CardOffer = (cardProps: CardOfferType) => {
  const equipmentWeight =
    cardProps.type === "offer" ? cardProps.equipmentWeight : 0;
  const {
    equipmentName,
    height,
    length,
    width,
    imageUrl,
    type,
    onQuoteClick,
    // onBookClick,
    // priceHT,
    // priceTTC,
    isProfessional,
    isPlan = false,
  } = cardProps;
  const CardPrice = () => {
    if (type === "offer") {
      const { priceHT, priceTTC } = cardProps;
      return (
        <div className="flex flex-col">
          <Label size="M" variant={"secondary"}>
            À partir de
          </Label>
          <div className="flex items-center gap-2">
            <Headline size={"h3"} as="div" className="whitespace-nowrap">
              {formatPriceWithCurrency(
                isProfessional ? priceHT : priceTTC,
                true,
              )}
            </Headline>
            <Label size="S" variant={"secondary"} className="text-gray-500">
              {isProfessional ? "HT" : "TTC"}
            </Label>
          </div>
        </div>
      );
    }
    return (
      <Label size="M" variant={"secondary"}>
        Votre offre sur mesure
      </Label>
    );
  };

  const CardAction = () => {
    return (
      <div className="mt-4 flex w-full flex-col gap-4 lg:mt-10">
        {type === "offer" ? (
          <ClintButton
            as="Button"
            variant={"primary"}
            type="button"
            isLoading={false}
            size="lg"
            className="w-full"
            onClick={cardProps.onBookClick}
          >
            Réserver
          </ClintButton>
        ) : null}
        <ClintButton
          as="Button"
          variant={type === "offer" ? "tertiary" : "primary"}
          type="button"
          hideIcon={type === "offer"}
          size="lg"
          className="w-full"
          onClick={onQuoteClick}
        >
          Obtenir un devis
        </ClintButton>
      </div>
    );
  };

  return (
    <div className="relative flex flex-col items-stretch justify-stretch rounded-[8px] border border-gray-200 bg-white lg:flex-row">
      <div className="items-strech flex flex-row flex-wrap justify-stretch">
        <div className="flex flex-1 items-center justify-center bg-gray-100 p-2">
          <img
            src={imageUrl}
            alt={equipmentName}
            className={cn(`h-auto w-full min-w-[200px] md:max-w-[275px]`, {
              "blur-sm": type === "no-offer",
            })}
          />
        </div>
        <div className="flex flex-1 basis-[300px] flex-col gap-4 border-b border-gray-200 p-4 lg:border-t-0 lg:p-6 lg:py-12">
          <Headline size="h5" as="div">
            {equipmentName}
          </Headline>
          {type === "offer" ? (
            <div className="flex flex-wrap gap-2">
              <Tag type="Square" variant="Light" className="whitespace-nowrap">
                {`Largeur : ${width || 0}cm`}
              </Tag>
              <Tag type="Square" variant="Light" className="whitespace-nowrap">
                {`Hauteur : ${height || 0}cm`}
              </Tag>
              <Tag type="Square" variant="Light" className="whitespace-nowrap">
                {`Longueur : ${length || 0}cm`}
              </Tag>
            </div>
          ) : null}
          <div className="hidden flex-wrap gap-3 lg:flex">
            <CheckedItem title="Dépose d'une benne" />
            <CheckedItem title="Retrait d'une benne" />
            <CheckedItem title="Location de la benne" />
            <CheckedItem
              title={
                isPlan
                  ? `1 trajet & ${equipmentWeight} tonne inclue`
                  : "1 trajet"
              }
            />
            <CheckedItem title="Traitement des déchets en centre agréé" />
            <CheckedItem title="Annulation gratuite 48h avant le jour de prestation" />
          </div>
        </div>
      </div>
      <div className="flex w-full max-w-full flex-col items-start justify-center self-stretch border-l border-gray-200 p-4 lg:max-w-[275px] lg:p-12">
        <CardPrice />
        <CardAction />
      </div>
    </div>
  );
};

export const CheckedItem = ({ title }: { title: string }) => {
  return (
    <div className="flex items-center gap-2">
      <Check aria-hidden="true" className="size-5 shrink-0 text-green-600" />
      <Label size="S" variant={"secondary"}>
        {title}
      </Label>
    </div>
  );
};
