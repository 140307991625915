import { ToastDescription, ToastTitle } from "@radix-ui/react-toast";
import { Link } from "@remix-run/react";
import { useEnv } from "app/root.tsx";
import { useIsClient } from "hooks/useIsClient.tsx";
import { useLocalStorage } from "hooks/useLocalStorage.tsx";
import {
  Toast,
  ToastAction,
  ToastProvider,
  ToastViewport,
} from "./shadcn-ui/toast.tsx";

export const CookieConsentBanner = () => {
  const [displayCookieBanner, setDisplayCookieBanner] = useLocalStorage(
    "display-cookie-banner",
    "true",
  );
  const isClient = useIsClient();
  const GOODCOLLECT_ENV = useEnv()?.GOODCOLLECT_ENV || "production";
  const showCookieBanner = displayCookieBanner === "true";

  if (!isClient) return null;
  if (!showCookieBanner) return null;
  if (GOODCOLLECT_ENV === "test") return null;

  return (
    <ToastProvider>
      <Toast role="alert" className="my-2 flex flex-col gap-2">
        <div className="grid gap-1">
          <ToastTitle className="text-sm">
            Ce site utilise des cookies essentiels à son fonctionnement.
          </ToastTitle>

          <ToastDescription className="text-xs">
            <span>
              En utilisant GoodCollect, vous acceptez la{" "}
              <Link
                to="/rgpd"
                prefetch="intent"
                className="font-semibold underline"
              >
                Politique de Confidentialité de GoodCollect.
              </Link>
            </span>
          </ToastDescription>
        </div>
        <ToastAction
          onClick={() => {
            setDisplayCookieBanner("false");
          }}
          altText="Fermer la bannière de cookie"
        >
          Fermer la bannière de cookie
        </ToastAction>
      </Toast>
      <ToastViewport className="sm:left-0" />
    </ToastProvider>
  );
};
