import { type RenderableTreeNodes, renderers } from "@markdoc/markdoc";
import { useEnv } from "app/root";
import { OptimizedImage } from "components/OptimizedImage.tsx";
import * as React from "react";
import { cn } from "utils/utils.ts";

type Props = { className?: string; content: RenderableTreeNodes };

export function Markdown({ content, className }: Props) {
  return (
    <div
      className={cn(
        "prose prose-li:items-baseline prose-hr:my-8 prose-headings:font-medium prose-headings:mb-4 prose-headings:scroll-mt-20 prose-headings:text-start prose-headings:text-midnightblue prose-p:text-justify prose-p:text-midnightblue prose-li:my-1 prose-li:gap-2 marker:prose-ul:text-midnightblue prose-ul:pl-4 prose-a:text-teal prose-a:underline first:prose-p:mt-0 mx-auto mt-8 max-w-full",
        className,
      )}
    >
      {renderers.react(content, React, {
        components: {
          Image: ({ alt, source }: { source: string; alt: string }) => {
            return (
              <OptimizedImage
                className="rounded-clint my-0 border border-gray-200"
                source={source}
                alt={alt}
                imageProps={{
                  minHeight: "100%",
                  objectFit: "cover",
                }}
              />
            );
          },
          GoodcollectPhone: (props) => {
            const { phoneDisplay, phoneLink } = useEnv() || {};

            return (
              <>
                <a className={"text-secondary underline"} href={phoneLink}>
                  {phoneDisplay}
                </a>
                {props.children}
              </>
            );
          },
        },
      })}
    </div>
  );
}
